<template>
    <div style="padding: 20px;">
        <template>
            <TableCommon isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
                <el-table-column
                label="支付状态"
                align="center">
                    <template slot-scope="scope">
                       <span>{{ scope.row.payStatus == 0 ? '未支付' : scope.row.payStatus == 1 ? '已支付' : '已退款'}}</span>
                    </template>
                </el-table-column>
            </TableCommon>
        </template>
        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>
<script>
import TableCommon from '../../../components/TableCommon.vue';
export default {
    data() {
        return {
            vkey: 'memberOrderPage',
            formInline: {
                pageNum: 1,
                pageSize: 10
            },
            tableColumn: [
                { label: '序号', type: 'index', align: 'center', width: '60' },
                { label: '用户姓名', prop: 'username', align: 'center' },
                { label: '订单编号', prop: 'orderNo', align: 'center' },
                { label: '时长', prop: 'duration', align: 'center' },
                { label: '总额', prop: 'amount', align: 'center' },
                { label: '下单时间', prop: 'createTime', align: 'center' },
                { label: '支付时间', prop: 'payTime', align: 'center' }
            ]
        }
    },
    components: {
        TableCommon
    },
    computed: {
        tableData(){
            let arr = this.$store.state.manage.views[this.vkey].list || [];
            arr.map(item=>{
                item.duration = item.duration == -1 ? '终身' : item.duration + '月'
            })
            return arr;
        },
        total() {
            return this.$store.state.manage.views[this.vkey].paging.total;
        }
    },
    created(){
        this.onLoadList()
    },
    methods: {
        // 列表
        onLoadList(){
           this.$store.dispatch('manage/list', {
                vkey: this.vkey,
                reset: true,
                query: this.formInline,
                interfaceName: 'getOrderList'
           })
        },
        // 分页
        handleCurrentChange(val){
            this.formInline.pageNum = val;
            this.onLoadList();
        }
    }
  }
</script>
<style lang="less" scoped>
</style>